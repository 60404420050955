@import "style/colors";

$box-v-space: 12px;
$box-h-space: 32px;

$box-border: $midgray;
$box-foreground: $gray;
$box-background: $darkgray;

@mixin box($width) {
	background-color: $box-background;
	border: 1px solid $box-border;
	border-radius: 4px;

	overflow: hidden;

	.content {
		width: #{$width - ($box-h-space * 2)};
		padding: $box-v-space $box-h-space;
	}
}