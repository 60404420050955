@import "components/Cover/style";

.covers {
	max-width: 1200px;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	gap: 8px;

	.filler {
		width: $coverWidth;
	}
}
