@import "style/colors";

.gallery {
	&-title {
		width: 70%;
		margin-top: 24px;
		border-top: 1px solid $gray;

		h2 {
			margin-top: -20px;
			padding: 0px 18px;
			font-size: 18pt;
			font-weight: 100;
			letter-spacing: 5pt;

			background-color: $background;
		}
	}

	.images {
		margin: 32px 0px;
		display: grid;
		grid-template-columns: repeat(3, 30vw);
		grid-auto-rows: 12vw;
		gap: 8px;

		.image {
			grid-column-end: span 1;
			grid-row-end: span 4;

			background-position: center;
			background-size: cover;

			&.landscape {
				grid-column-end: span 3;
				grid-row-end: span 3;
			}
		}
	}
}

/* Media for mobile */
@media only screen and (max-width: 768px) {
	.gallery {
		//background-color: red;

		&-title {
			width: 90%;

			h2 {
				margin-top: -2.5vw;
				padding: 0px 8px;
				font-size: 3vw;
			}
		}

		.images {
			grid-template-columns: 100vw;
			gap: 3vw;

			.image {
				margin: 0px 3vw;
				grid-row-end: span 12;

				&.landscape {
					grid-column-end: span 1;
					grid-row-end: span 4;
				}
			}
		}
	}
}