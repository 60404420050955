div, 
section,
header,
footer {
	display: flex;
	flex-direction: column;
	
	&.reverse { flex-direction: column-reverse; }
	&.row { flex-direction: row; }
	&.row-reverse { flex-direction: row-reverse; }

	&.align {
		&-top {
			justify-content: flex-start;
		}
		&-bottom {
			justify-content: flex-end;
		}
		&-left {
			align-items: flex-start;
		}
		&-right {
			align-items: flex-end;
		}

		&-center {
			justify-content: center;
			align-items: center;
		}
		&-horizontal-center {
			align-items: center;
			&.row {
				justify-content: center;
				align-items: inherit;
			}
		}
		&-vertical-center {
			justify-content: center;
			&.row {
				align-items: center;
				justify-content: inherit;
			}
		}
	}

	&.expand {
		flex-grow: 1;
	}
}