@import "style/colors";

.galleries-editor {
	height: calc(100vh - 62px);

	.galleries {
		width: 460px;
		//flex-shrink: 0;

		border-right: 1px solid $midgray;

		.galleries-content {
			overflow-y: auto;

			.gallery {
				height: 76px;
				flex-shrink: 0;
				border-bottom: 1px solid $midgray;
				overflow: hidden;

				transition: height 100ms ease-in-out;

				&.selected {
					height: 500px;

					&>.handle {
						.label {
							color: $focus;
						}
					}
				}

				&.dragOver {
					&>.handle {
						background-color: $midgray;

						.label {
							color: white;
						}
					}
				}

				.handle {
					height: 76px;
					flex-direction: row;
					align-items: center;

					cursor: pointer;

					&:hover {
						background-color: $darkgray;
					}

					.label {
						flex-direction: row;
						flex: 1;
						font-size: 16pt;
						color: $gray;

						.bullet {
							width: 10px;
							height: 10px;
							margin: 12px;
							border-radius: 5px;
							background-color: rgba($warn, 0);
							box-shadow: 0px 0px 1px transparent;

							transition: all 250ms ease-in-out;

							&.changes {
								background-color: rgba($warn, 1);
								box-shadow: 0px 0px 12px $warn;
							}
						}
					}
				}

				.properties {
					height: 0px;
					padding: 0px 24px;
					color: $gray;

					.row {
						margin-top: 24px;
					}

					.cover {
						width: 150px;
						height: 200px;
						background-color: $midgray;
					}

					.options {
						padding: 0px 12px;

						.changes-buttons {
							flex-direction: row-reverse;
							justify-content: space-between;
						}
					}
				}
			}
		}

		.galleries-footer {
			padding: 24px;
			border-top: 1px solid $midgray;
			justify-content: space-between;
		}
	}

	.current-gallery {
		margin-left: 160px;

		.images {
			display: grid;
			grid-template-columns: repeat(3, 120px);
			grid-auto-rows: 40px;
			gap: 8px;

			overflow-y: auto;

			.image {
				grid-column-end: span 1;
				grid-row-end: span 4;

				background-position: center;
				background-size: cover;

				cursor: pointer;

				&.landscape {
					grid-column-end: span 3;
					grid-row-end: span 4;
				}

				&.selected {
					box-shadow: inset 0px 0px 4px 6px $success;
				}
			}
		}

		.settings {
			width: 500px;
			border-left: 1px solid $midgray;

			&-content {
				&>div {
					padding: 24px;
					border-bottom: 1px solid $midgray;
				}

				.image-type {
					.box-size {
						width: 60px;
						height: 30px;

						margin: 4px;

						background-color: $midgray;
					}
				}
			}

			&-footer {
				padding: 24px;
				border-top: 1px solid $midgray;
			}
		}
	}
}