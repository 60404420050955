@import "style/colors";

.load-images {
	width: 100%;
	height: 100%;
	position: fixed;
	background-color: rgba(black, 0.6);

	&-popup {
		width: 1100px;
		height: 800px;
		background-color: $background;

		&-content {
			margin: 32px;

			h2 {
				font-weight: 100;
			}

			.drop-container {
				height: 450px;
				margin: 32px 0px;
				//border: 2px dotted $gray;
				background-color: $midgray;

				.thumbs {
					height: 100%;
					padding: 8px 0px 0px 8px;

					display: flex;
					align-content: flex-start;
					flex-wrap: wrap;
					gap: 12px;

					overflow-y: scroll;

					.thumb {
						width: 190px;
						height: 128px;

						display: flex;

						font-size: 10pt;
						background-color: $darkgray;
						border: 1px solid #121416;
						border-radius: 6px;
						box-shadow: 2px 4px 12px rgba(black, 0.8);

						overflow: hidden;

						.image {
							width: 128px;
							height: 128px;
							background-position: center;
							background-size: cover;
						}

						.options {
							margin: 8px;

							.delete {
								align-items: flex-end;
							}
						}
					}
				}
			}

			.buttons {
				justify-content: space-between;
				align-items: flex-end;
			}
		}
	}
}