@import "style/colors";
@import "style/elements/box";

$width: 512px;
$thickness: 8px;
$space: 8px;

.toast {
	@include box($width);

	position: fixed;
	left: 50%;
	bottom: -100px;
	margin-left: #{ -$width / 2 };

	flex-direction: row;

	font-family: "Poppins", Arial, Helvetica, sans-serif;
	font-size: 12pt;
	font-weight: 400;

	transition: bottom 1s ease-in-out;

	&.error {
		&>.decorator {
			background-color: $error;
		}
	}

	&.warn {
		&>.decorator {
			background-color: $warn;
		}
	}

	&.success {
		&>.decorator {
			background-color: $success;
		}
	}

	&.visible {
		bottom: 24px;
	}

	.content {
		padding-left: 0;

		flex: 1;

		.title {
			margin-right: 4px;
			font-weight: 500;
		}
	}

	.decorator {
		width: $thickness;
		margin: $space;
		background-color: $focus;
		border-radius: $thickness;
	}

	.options {
		padding: 0px #{ $space * 2 };
		justify-content: center;

		.button {
			font-size: 9pt;
			font-weight: 500;
			cursor: pointer;

			transition: color 100ms ease-in-out;

			&:hover {
				text-decoration: underline;
				color: $focus;
			}
		}
	}
}