$highlight: #c5c7c9;
$foreground: #858789;
$background: #151719;

$gray: #4b4d4f;
$midgray: #252729;
$darkgray: #1b1d1f;

$focus: #359;
$error: #935;
$success: #395;
$warn: #a50;
