@import "style/colors";

.button,
button {
	cursor: not-allowed;

	&.active {
		cursor: pointer;

		&.success {
			color: $success;
		}

		&.warn {
			color: $warn;
		}

		&.error {
			color: $error;
		}
	}
}

.button {
	font-family: "Poppins", Arial, Helvetica, sans-serif;
	font-weight: 300;
	color: $gray;

	&.active {
		color: $focus;

		&:hover {
			text-decoration: underline;
		}
	}
}

button {
	border-style: none;

	padding: 12px;
	border-radius: 4px;
	border: 1px solid #999;
	background-color: #aaa;
	color: gray;

	&.active {
		border-color: dodgerblue;
		background-color: dodgerblue;
		color: white !important;
	}
}