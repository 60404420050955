@import "style/colors";
@import "style/elements/box";

.login {
	padding: 72px;
	align-items: center;
	color: $box-foreground;

	.form {
		@include box(350px);

		.content {
			align-items: center;
			gap: 12px;
		}

		h3 {
			padding: $box-v-space;
			font-size: 24pt;
			font-weight: 100;
		}

		button {
			margin: $box-v-space;
		}
	}
}