@import "style/colors";
@import "style/elements/box";

.input {
	width: 100%;
	font-family: "Poppins";
	font-size: 12pt;
	font-weight: 300;

	&.check {
		display: flex;
		flex-direction: row;
		cursor: pointer;

		&:hover {
			.toggle {
				.track {
					background-color: rgba($gray, 0.5);
				}

				.circle {
					background-color: $foreground;
				}
			}
		}

		&.checked {
			.toggle {
				.track {
					background-color: rgba($focus, 0.5);
				}

				.circle {
					background-color: $focus;
					margin-left: 20px;
				}
			}
		}

		span {
			padding: 12px;
			flex: 1;
		}

		.toggle {
			width: 64px;
			height: 100%;
			justify-content: center;
			align-items: center;

			.track {
				width: 42px;
				height: 12px;
				flex-direction: row;
				align-items: center;
				background-color: $midgray;
				border-radius: 6px;

				transition: all 100ms ease-in-out;
			}

			.circle {
				width: 24px;
				height: 24px;
				margin-left: 0px;
				flex-shrink: 0;
				background-color: $gray;
				border-radius: 24px;

				transition: all 100ms ease-in-out;
			}
		}
	}

	&.text {
		transition: color 250ms ease-in-out;

		&.focus {
			color: $foreground;

			&>.line .fill {
				width: 100%;
			}
		}

		&.error {
			color: $error;

			&>.line .fill {
				width: 100%;
				background-color: $error;
			}
		}

		p {
			padding: 4px;
		}

		input,
		textarea {
			padding: 12px;
			background-color: $background;

			&::placeholder {
				color: $gray;
			}
		}

		textarea {
			min-height: 150px;
			resize: vertical;
		}

		.line {
			width: 100%;
			height: 2px;
			flex-direction: row;
			justify-content: center;
			background-color: $box-border;

			.fill {
				width: 0%;
				height: 100%;
				background-color: $focus;

				transition: all 250ms ease-in-out;
			}
		}
	}
}