@import "style/colors";
@import "style/elements/box";

.contact-me {
	align-items: center;
	gap: 24px;

	@include box(840px);

	box-shadow: 2px 8px 24px rgba(black, 0.4);

	.content {
		margin: 24px 0px;
		gap: 24px;

		.row {
			gap: 12px;
		}
	}

	/*.title {
		font-size: 18pt;
		text-align: center;
		color: $gray;
	}

	.box {
		@include box(250px);

		box-shadow: 2px 8px 24px rgba(black, 0.4);

		padding-bottom: 16px;

		.content {
			gap: 24px;

			h1 {
				font-size: 22pt;
				font-weight: 100;
			}

			.row {
				gap: 24px;

				cursor: pointer;

				.icon {
					align-items: center;
					color: $gray;

					transition: color 200ms ease-in-out;

					svg {
						height: 32px;
					}

					&.twitter:hover {
						color: #266ae7;
					}

					&.instagram:hover {
						color: #bd1a7e;
					}
				}
			}
		}
	}*/
}

$box-width: calc(100vw * 0.75);

/* Media for mobile */
@media only screen and (max-width: 768px) {
	.contact-me {
		align-items: center;

		.box {
			.content {
				.row {
					.icon {
						&.twitter {
							color: #266ae7;
						}

						&.instagram {
							color: #bd1a7e;
						}
					}
				}
			}
		}
	}
}