@import "style/colors";
@import "style/elements/box";

$animDuration: 1.5s;

/* Shuttle */
$deg: 75deg;

$openX: -68px; //-60px;
$openY: 106px; //96px;

$closeX: -6px;
$closeY: 10px;

.about-me {
	width: 1024px;
	gap: 64px;

	.side-column {
		.profile-picture {
			width: 280px;
			height: 280px;

			border: 2px solid;
			border-color: $midgray transparent;
			border-radius: 100%;

			transform: rotate(-45deg);

			.shutter {
				width: 256px;
				height: 256px;
				position: relative;

				justify-content: center;
				align-items: center;

				background-color: black;
				border-radius: 100%;
				overflow: hidden;

				box-shadow: 2px 4px 12px rgba(black, 0.4);

				transform: rotate(45deg);
				animation: rotation 10s infinite linear reverse;

				.picture {
					width: 245px;
					height: 245px;
					border-radius: 100%;
					overflow: hidden;

					//background-image:
					//radial-gradient(circle 16px at 60% 40%, rgba(white, 0.25) 50%, transparent),
					//radial-gradient(black, #111, black);

					&::after {
						content: "";
						width: 100%;
						height: 100%;
						background-image:
							radial-gradient(circle 140px, transparent 50%, black),
							url("https://pbs.twimg.com/profile_images/1510937027902062597/gQpSen2B_400x400.jpg");
						background-position: center;
						background-size: cover;
						opacity: 1;

						animation: showImage $animDuration forwards linear;
					}
				}

				.thingies-content {
					width: 0px;
					height: 0px;
					position: absolute;
					overflow: visible;

					.thingy {
						width: 180px;
						height: 120px;
						position: absolute;
						top: 0px;
						left: 12px;

						flex-shrink: 0;
						overflow: hidden;

						transform-origin: -12px 0px;

						&::before {
							content: "";
							width: 128px;
							height: 128px;
							background-color: #222;
							transform: translate(18px, -64px) scaleY(1.7321) rotate(45deg);
						}

						@for $i from 1 through 6 {
							&:nth-child(#{$i}) {
								animation: #{clickShutter + $i} $animDuration forwards ease-in-out;
							}
						}
					}
				}
			}
		}

		.social-media {
			margin-top: 32px;
			text-align: center;
			font-size: 18pt;

			a {
				margin: 24px;

				.icon {
					color: $midgray;

					filter: drop-shadow(2px 2px 4px rgba(black, 0.6));

					transition: all 0.2s;

					svg {
						height: 42px;
					}

					&:hover {
						margin-top: -6px;
						margin-bottom: 6px;
						filter: drop-shadow(2px 8px 4px rgba(black, 0.6));
					}

					&.twitter:hover {
						color: #266ae7;
					}

					&.instagram:hover {
						color: #bd1a7e;
					}
				}
			}
		}
	}

	.content {
		h2 {
			margin: 32px 0px;
			font-size: 90pt;
			font-weight: 300;
			color: $gray;
		}

		h3 {
			margin-bottom: 16px;
			font-size: 24pt;
			font-weight: 200;
			color: $gray;
		}

		p {
			margin-bottom: 64px;
			font-family: Poppins;
			font-size: 18pt;
			font-weight: 200;
			text-align: justify;

			strong {
				font-weight: 300;
			}
		}
	}
}

/* Animations */
@for $i from 1 through 6 {
	@keyframes #{clickShutter + $i} {
		0% {
			transform: rotate(#{60deg * ($i - 1) + $deg}) translate($openX, $openY);
		}

		33% {
			transform: rotate(#{60deg * ($i - 1) + $deg}) translate($openX, $openY);
		}

		67% {
			transform: rotate(#{60deg * ($i - 1) + ($deg - 30deg)}) translate($closeX, $closeY);
		}

		100% {
			transform: rotate(#{60deg * ($i - 1) + $deg}) translate($openX, $openY);
		}
	}
}

@keyframes showImage {
	0% {
		opacity: 0;
	}

	70% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

/* Media for mobile */
@media only screen and (max-width: 768px) {
	.about-me {
		width: 100%;
	}
}