@import "style/colors";

.loading {
	.icon {
		height: 32px;
	}

	// Default animation
	.gamepad-icon {
		animation: gamepad 1.5s infinite;
	}

	.camera-icon {
		animation: camera 1.5s infinite;
	}

	.images-icon {
		height: 32px;
		overflow: hidden;

		.icons-content {
			gap: 32px;
			animation: image 1.5s infinite;
		}
	}

	.dots {
		margin: 0 32px;
		flex-direction: row;
		gap: 24px;

		.dot {
			width: 4px;
			height: 4px;
			border: 1px solid $midgray;
			border-radius: 100%;

			animation: dot 1s alternate infinite ease-in-out;

			&:nth-child(2) {
				animation-delay: .33s;
			}

			&:nth-child(3) {
				animation-delay: .67s;
			}
		}
	}

	// Send email animation
	.mail-icon {
		animation: sendMail 1s infinite linear;

		.icon {
			animation: growMail 1s infinite ease-in-out;
		}
	}
}

// Default animation
@keyframes gamepad {
	0% {
		transform: rotate(0deg);
		color: $midgray;
	}

	5% {
		transform: rotate(20deg);
	}

	10% {
		transform: rotate(-10deg);
		color: $highlight;
	}

	15% {
		transform: rotate(10deg);
	}

	20% {
		transform: rotate(-10deg);
	}

	32% {
		transform: rotate(0deg);
		color: $highlight;
	}

	42% {
		color: $midgray;
	}

	to {
		color: $midgray;
	}
}

@keyframes camera {
	from {
		color: $midgray;
	}

	33% {
		transform: translateY(0px);
	}

	37% {
		color: $midgray;
	}

	44% {
		transform: translateY(8px);
		color: $highlight;
	}

	50% {
		transform: translateY(0px);
		color: $highlight;
	}

	67% {
		color: $midgray;
	}

	to {
		color: $midgray;
	}
}

@keyframes image {
	from {
		transform: translateY(-64px) scale(1);
		color: $highlight;
	}

	10% {
		color: $midgray;
	}

	67% {
		transform: translateY(-64px) scale(1);
		color: $midgray;
	}

	77% {
		transform: translateY(-56px) scale(0.75);
		color: $highlight;
	}

	90% {
		transform: translateY(-8px) scale(0.75);
	}

	100% {
		transform: translateY(0px) scale(1);
		color: $highlight;
	}
}

@keyframes dot {
	50% {
		transform: scale(0.75);
	}
}

// Send email animation
@keyframes sendMail {
	from {
		margin-left: 0px;
		margin-right: 100px;
	}

	to {
		margin-left: 100px;
		margin-right: 0px;
	}
}

@keyframes growMail {
	from {
		transform: scale(0.75);
		opacity: 0;
	}

	50% {
		transform: scale(1);
		opacity: 1;
	}

	to {
		transform: scale(0.75);
		opacity: 0;
	}
}