@import "style/colors";

.editor {
    &-header {
        padding: 12px 24px;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $midgray;

        .title {
            font-size: 18pt;
            color: $gray;
        }
    }
}