@import "style/colors";

$coverWidth: 360px;
$coverHeight: 540px;

.cover {
	margin-bottom: 32px;
	text-align: center;
	text-decoration: none;
	color: $foreground;

	.image {
		width: $coverWidth;
		height: $coverHeight;

		background-color: $darkgray;
		background-position: center;
		background-size: cover;
	}

	.label {
		padding-top: 12px;
	}
}