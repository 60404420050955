@import "style/colors";

body {
	font-family: "Oswald", Arial, Helvetica, sans-serif;
	font-size: 14pt;
	font-weight: 200;
	background-color: $background;
	color: $foreground;
}

.app {
	height: 100vh;
	overflow-y: auto;
}