@import "style/colors";

.header {
	margin-bottom: 24px;
	padding-top: 24px;

	align-items: center;

	&-content {
		.title {
			h1 {
				padding: 0px;
				margin-left: -4px;
				font-size: 46pt;
				font-weight: 100;
			}

			h2 {
				display: flex;
				justify-content: space-between;

				font-size: 12pt;
				font-weight: 200;
				letter-spacing: 4pt;

				span {
					margin-right: -4pt;
				}
			}
		}

		nav {
			width: 100%;

			display: flex;
			justify-content: space-between;
			gap: 4px;

			cursor: pointer;

			.burger-menu {
				display: none;
			}

			a {
				padding: 8px 0px;
				font-size: 12pt;
				font-weight: 100;
				text-decoration: none;
				color: inherit;

				&:hover {
					color: $highlight;
				}
			}
		}
	}
}

/* Media for mobile */
@media only screen and (max-width: 768px) {
	.header {
		width: 100vw;
		padding: 0px;
		position: absolute;

		background-color: $background;

		&-content {
			margin: 4px 12px;

			.title {
				h1 {
					margin-left: -2px;
					font-size: 8vw;
				}

				h2 {
					margin-top: -2vw;
					font-size: 3vw;
					letter-spacing: 0.5vw;

					span {
						margin-right: -0.5vw;
					}
				}
			}

			nav {
				all: unset;

				width: 75vw;
				height: 100vh;

				//padding-top: 20vw;

				position: fixed;
				right: -75vw;
				top: 0px;

				display: flex;
				flex-direction: column;
				justify-content: center;
				gap: 16px;

				background-color: transparent;

				transition: all 200ms ease-in-out;

				&.open {
					right: 0vw;
					background-color: $darkgray;
					box-shadow: -16px 0px 64px 32px rgba(black, 0.75);

					&>.burger-menu {
						left: 6vw;
					}
				}

				.burger-menu {
					position: absolute;
					top: 5vw;
					left: -10vw;
					display: flex;

					transition: left 200ms ease-in-out;

					svg {
						height: 7vw;
					}
				}

				a {
					padding: 16px 32px;
					font-size: 4vw;
				}
			}
		}
	}
}