* {
	margin: 0;
	padding: 0;
	list-style: none;
	outline: none;
}

a {
	text-decoration: none;
}

button,
input,
textarea {
	all: unset;
}